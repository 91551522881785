<section class="mat-typography container">
    <div mat-dialog-title class="mat-dialog-title">
        <h2>Unsaved Changes</h2>
    </div>

    <mat-dialog-content class="mat-typography ">
        <p>{{data.message}}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button color="gray" [mat-dialog-close]="false">{{data.cancelButtonText}}</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>{{data.confirmButtonText}}</button>

    </mat-dialog-actions>
</section>
