// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hostingEnvironment: 'local',
  oktaconfigvalues: {
    clientId: '0oa19mvnlum2LOPRP0h8',
    issuer: 'https://teamhealth.oktapreview.com/oauth2/default',
    redirectUri: 'http://localhost:4200/login/callback',
    errorRedirectUrl: 'https://teamhealth.samanage.com/catalog_items/1419257-okta-report-form/service_requests/new.portal'
  },
  defaultNPI: 1063893451,
  canViewSpecialtyService: true,
  defaultEmail: 'sara_chapman@teamhealth.com',
  ANALYTICS_URL: "https://mz1vvd3qbf.execute-api.us-east-1.amazonaws.com/Prod",

  //ASPNETCORE_URLS:"https://api-dev-universalform.s3universalform.it-dev.tmhlabs.net"
  //ASPNETCORE_URLS: "https://pz5u6ps0vh.execute-api.us-east-1.amazonaws.com/Prod"
  ASPNETCORE_URLS: "https://21ptjof582.execute-api.us-east-1.amazonaws.com/Prod"
};
