import { Component, Inject, OnInit } from '@angular/core';
import { UnsavedChangesAction } from './unsaved-changes-action.enum';

export class UnsavedChangesDialogData {
    hasValidationErrors: boolean;
}

@Component({
    selector: 'app-unsaved-changes-dialog',
    templateUrl: './unsaved-changes-dialog.component.html',
    styleUrls: ['./unsaved-changes-dialog.component.scss']
})
export class UnsavedChangesDialogComponent {

    //constructor(
    //    public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>,
    //    @Inject(MAT_DIALOG_DATA) public dialogData: UnsavedChangesDialogData
    //) {

    //}

    //ngOnInit(): void {

    //}

    //saveAndContinue() {
    //    this.dialogRef.close(UnsavedChangesAction.Save);
    //}

    //justContinue() {
    //    this.dialogRef.close(UnsavedChangesAction.IgnoreSave);

    //}

    //cancel() {
    //    this.dialogRef.close(UnsavedChangesAction.Cancel);
    //}
}
