export enum ClaimTypes {
    AuthenticationInstant = "http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationinstant",
    AuthenticationMethod = "http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationmethod",
    Expiration = "exp",
    FirstName = "http://schemas.teamhealth.com/ws/2020/01/identity/claims/firstName",
    Issuer = "iss",
    Jti = "jti",
    LastName = "http://schemas.teamhealth.com/ws/2020/01/identity/claims/lastName",
    ModuleAccess = "universal-form/2020/01/identity/claims/moduleAccess",
    NameIdentifier = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier",
    NotBefore = "nbf",
    PrimaryEmail = "http://schemas.teamhealth.com/ws/2020/01/identity/claims/email",
    Roles = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
    Username = "http://schemas.teamhealth.com/ws/2020/01/identity/claims/username",
}

export enum FacilityAccessValues {
    All = "All",
    Group = "Group",
    Individual = "Individual"
}

export enum ModuleAccessValues {
  ShadowRounding = "Shadow Rounding Form",
  PACIW = "PAC Interactive Worklist",
  SpecialtyService = "Specialty Services Form",
  EmProductivityCalc = "EM Productivity Calculator"
}
