import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../modules/shared/material.module';
import { AdminComponent } from './admin/admin.component';
import { UniversalTableComponent } from './uf-table/universal-table.component';
import { FacilitySelectorComponent } from '../modules/shared/facility-selector/facility-selector.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { WaterfallChartComponent } from './waterfall-chart/waterfall-chart.component';
import { ToolbarMenuComponent } from './toolbar-menu/toolbar-menu.component';
import { PatientFilterComponent } from '../modules/pac-interactive/patient-filter/patient-filter.component';

@NgModule({
  declarations: [
    AdminComponent,
    UniversalTableComponent,
    FacilitySelectorComponent,
    BarChartComponent,
    WaterfallChartComponent,
    ToolbarMenuComponent,
    PatientFilterComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    MaterialModule
  ],
  exports: [
    AdminComponent,
    UniversalTableComponent,
    FacilitySelectorComponent,
    BarChartComponent,
    WaterfallChartComponent,
    ToolbarMenuComponent,
    PatientFilterComponent
  ]
})

export class SharedModule { }

