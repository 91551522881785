<div class="landing-page" *ngIf="isViewable">
  <div class="welcome-message">Welcome {{ username }}</div>
  <div class="welcome-message">Current Environment {{ currentEnvironment }}</div>

  <div class="directions">
    <div *ngFor="let flag of featureFlags">
      <div>
        <span>Name: {{flag.name}}</span>
        <span>
          <mat-checkbox (change)="updateFeatureFlags($event, flag)" [ngModel]="flag.value"></mat-checkbox>
        </span>
      </div>
      <div>Description: {{flag.description}}</div>
    </div>
  </div>
  <div class="directions">
    <div *ngFor="let data of adminData">
      <div>Name: {{data.key}}</div>
      <input matInput [(ngModel)]="data.value" />
    </div>
  </div>
  <button mat-flat-button class="nav-button" color="primary" (click)="save()">Save</button>
</div>
