export enum MonthEnum {
  'January' = 1,
  'February' = 2,
  'March' = 3,
  'April' = 4,
  'May' = 5,
  'June' = 6,
  'July' = 7,
  'August' = 8,
  'September' = 9,
  'October' = 10,
  'November' = 11,
  'December' = 12
}

export enum MonthShortEnum {
  'Jan' = 1,
  'Feb' = 2,
  'Mar' = 3,
  'Apr' = 4,
  'May' = 5,
  'Jun' = 6,
  'Jul' = 7,
  'Aug' = 8,
  'Sep' = 9,
  'Oct' = 10,
  'Nov' = 11,
  'Dec' = 12
}
