import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { UserDashboardComponent } from './dashboard/user-dashboard.component';
import { environment } from 'spa/environments/environment'; 
import {
  OKTA_CONFIG,
  OktaAuthGuard,
  OktaAuthModule,
  OktaCallbackComponent,
  OKTA_AUTH,
} from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { UserFormDataService } from './services/user-form-data.service';
import { GenerateUrlService } from './services/base-service';
import { MaterialModule } from './modules/shared/material.module';
import { ConfirmModalComponent } from './modules/shared/confirm-modal/confirm-modal.component';
import { UnsavedChangesDialogComponent } from './modules/shared/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { RedirectGuard } from './services/redirect.guard';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmStrings } from './modules/shared/models/confirm-strings';
import { ApiInterceptorService } from './services/api-interceptor.service';
import { AdminComponent } from './components/admin/admin.component';
import { SharedModule } from './components/shared.module';
import { ShadowRoundingGuard } from './guards/shadow-rounding.guard';

const oktaAuth = new OktaAuth(environment.oktaconfigvalues);
const appRoutes: Routes = [ 
  {
    path: 'login/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'dashboard',
    component: UserDashboardComponent,
    canActivate: mapToCanActivate([RedirectGuard])
  },
  {
    path: 'shadow-rounding',
    loadChildren: () => import('./modules/shadow-rounding/shared/shadow-rounding.module').then(m => m.ShadowRoundingModule),
    canActivate: [ShadowRoundingGuard]

  },
  {
    path: 'pac-interactive',
    loadChildren: () => import('./modules/pac-interactive/pac-interactive.module').then(m => m.PacInteractiveModule)
  },
  {
    path: 'specialty-services',
    loadChildren: () => import('./modules/specialty-services/shared/specialty-services.module').then(m => m.SpecialtyServicesModule)

  },
  {
    path: 'em-productivity',
    loadChildren: () => import('./modules/em-productivity/shared/em-productivity.module').then(m => m.EmProductivityModule)

  },
  {
    path: 'md-timesheet',
    loadChildren: () => import('./modules/md-timesheet/shared/md-timesheet.module').then(m => m.MdTimesheetModule)
  },
  {
    path: 'admin-md-timesheet',
    loadChildren: () => import('./modules/admin-mdt/shared/admin-mdt.module').then(m => m.AdminMdtModule)
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: mapToCanActivate([OktaAuthGuard]),
    pathMatch: 'full'
  },
  {
    path: '',
    component: UserDashboardComponent,
    canActivate: mapToCanActivate([RedirectGuard])
  }
];

@NgModule({
  declarations: [
    AppComponent,
    UserDashboardComponent,
    ConfirmModalComponent,
    UnsavedChangesDialogComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    OktaAuthModule,
    //NoopAnimationsModule,
    RouterModule.forRoot(appRoutes),
    MaterialModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      deps: [OKTA_AUTH],
      useClass: ApiInterceptorService
    },
    GenerateUrlService,
    [UserFormDataService],
    RedirectGuard,
    ConfirmStrings,
    { provide: OKTA_CONFIG, useValue: { oktaAuth } }
  ],
  
  bootstrap: [AppComponent]
})

export class AppModule { 
}
