import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { FormUser } from '../models/formUser';
import { ShadowRoundingComponent } from '../modules/shadow-rounding/shadow-rounding.component';
import { UserFormDataService } from '../services/user-form-data.service';
import { environment } from '../../environments/environment'

@Component({
  selector: 'app-user-auth',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})

export class UserDashboardComponent {

  isAuthenticated!: boolean;
  userName?: string;
  error?: Error;
  userEmail?: string;
  IsLaunchAccess: boolean = false;
  userForms: FormUser[] = [];
  loading: boolean = false;
  specialtyServiceUrl = 'specialty-services';
  canViewSpecialtyService = environment.canViewSpecialtyService;

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private userformservice: UserFormDataService,
    private router: Router) { }

  async ngOnInit() {

    this.loading = true;

    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    if (this.isAuthenticated) {
      const userClaims = await this.oktaAuth.getUser();
      this.userName = userClaims.name;
      this.userEmail = userClaims.email?.toString();

      //check for user form access
      if (this.userEmail) {
        this.userformservice.getUserForm(this.userEmail)
          .subscribe((userForms: FormUser[]) => {
            this.loading = false;
            if (!this.canViewSpecialtyService) {
              var specialtyServiceId = userForms.find(x => x.urlRoute == this.specialtyServiceUrl)?.id
              userForms = userForms.filter(x => x.id != specialtyServiceId);
            }
            this.userForms = userForms;
            //allow form access list to be used in other areas
            ShadowRoundingComponent.userForms = userForms;
          }, (error) => {
            this.loading = false
            //temp error return
            var errMsgDetails = "";
            if (error.error == null) { errMsgDetails = "Unknown error" } else { errMsgDetails = error.error };
            var errMsg = "Error: " + error.status + " - " + errMsgDetails;
            alert(errMsg);
          });
        //set IsLaunchAccess
      }

    }
  }

  navigate(urlRoute: string) {
    this.router.navigate([urlRoute])
  }

  openInfo(form: FormUser) {
    const route = form.urlRoute.split('-')
      .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join('');

    let routeName = '../../assets/EducationDocument'+route+'.pdf';

    window.open(routeName, "_blank")  }
}
