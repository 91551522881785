<div class="font-size">
  <div mat-menu-item class="menu-padding">
    <mat-selection-list #statusItems>
      <h3 matSubheader>
        Status
      </h3>
      <mat-list-item>
        <mat-list-option value="overdue" [(selected)]="overdue" (click)="updateFilter($event, statusItems, measureItems)" togglePosition="before">
          Overdue
        </mat-list-option>
      </mat-list-item>
      <mat-list-item>
        <mat-list-option value="twoWeeks" [(selected)]="twoWeeks" (click)="updateFilter($event, statusItems, measureItems)" togglePosition="before">
          Due in the next 2 weeks
        </mat-list-option>
      </mat-list-item>
      <mat-list-item>
        <mat-list-option value = "notDue" [(selected)]="notDue" (click)="updateFilter($event, statusItems, measureItems)" togglePosition="before">
          Not Due
        </mat-list-option>
      </mat-list-item>
    </mat-selection-list>
  </div>
  <div mat-menu-item class="menu-padding">
    <h3 matSubheader>Measures</h3>
    <mat-selection-list #measureItems >
      <!--<mat-list-item>
        <mat-list-option value = "AWE" [(selected)]="AWE" (click)="updateFilter($event, statusItems, measureItems)" togglePosition="before">
          Annual Wellness Exam
        </mat-list-option>
      </mat-list-item>-->
      <mat-list-item>
        <mat-list-option value = "FMV" [(selected)]="FMV" (click)="updateFilter($event, statusItems, measureItems)" togglePosition="before">
          Federally Mandated Visit
        </mat-list-option>
      </mat-list-item>
      <mat-list-item>
        <mat-list-option value = "ACP" [(selected)]="ACP" (click)="updateFilter($event, statusItems, measureItems)" togglePosition="before">
          Advanced Care Planning
        </mat-list-option>
      </mat-list-item>
    </mat-selection-list>
  </div>
</div>
