<nav class="navbar">
  <div *ngIf="displayNavigation" style="padding-left: 10px; width: 50px; background-color: #0057B8">
    <button mat-icon-button style="font-size: 25px; color: #ffffff; background-color: #0057B8" (click)="navigate()">
      <mat-icon>chevron_left</mat-icon>
    </button>
  </div>
  <div *ngIf="!displayNavigation" style="padding-left: 10px; width:14px;">
    &nbsp;
  </div>
  <!-- <a class="navbar-brand" id="brand" href="/">UniversalForm</a> -->
  <div class="navbar-brand" id="brand" [ngClass]="{'brand' : !displayNavigation}">{{title}}</div>
  <!-- <a id="login" href="#" *ngIf="!(authState)?.isAuthenticated" (click)="login()">Login</a>
  <a id="logout-button" href="#" *ngIf="(authState)?.isAuthenticated" (click)="logout()">Logout</a> -->
  <div>
    <button mat-icon-button *ngIf="displayHelp" (click)="openPDF()">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" fill="white" />
        <path d="M21.25 12.625C21.25 11.1332 20.6574 9.70242 19.6025 8.64752C18.5476 7.59263 17.1168 7 15.625 7C14.1332 7 12.7024 7.59263 11.6475 8.64752C10.5926 9.70242 10 11.1332 10 12.625C10 12.9234 10.1185 13.2095 10.3295 13.4205C10.5405 13.6315 10.8266 13.75 11.125 13.75C11.4234 13.75 11.7095 13.6315 11.9205 13.4205C12.1315 13.2095 12.25 12.9234 12.25 12.625C12.25 11.9575 12.4479 11.305 12.8188 10.75C13.1896 10.1949 13.7167 9.76235 14.3334 9.50691C14.9501 9.25146 15.6287 9.18462 16.2834 9.31485C16.9381 9.44508 17.5395 9.76651 18.0115 10.2385C18.4835 10.7105 18.8049 11.3119 18.9352 11.9666C19.0654 12.6213 18.9985 13.2999 18.7431 13.9166C18.4876 14.5333 18.0551 15.0604 17.5 15.4312C16.945 15.8021 16.2925 16 15.625 16C15.3266 16 15.0405 16.1185 14.8295 16.3295C14.6185 16.5405 14.5 16.8266 14.5 17.125V19.375C14.5 19.6734 14.6185 19.9595 14.8295 20.1705C15.0405 20.3815 15.3266 20.5 15.625 20.5C15.9234 20.5 16.2095 20.3815 16.4205 20.1705C16.6315 19.9595 16.75 19.6734 16.75 19.375V18.1375C18.0205 17.8782 19.1624 17.1878 19.9825 16.1833C20.8025 15.1787 21.2503 13.9217 21.25 12.625Z" fill="#143788" />
        <path d="M15.625 25.0001C16.2463 25.0001 16.75 24.4964 16.75 23.8751C16.75 23.2538 16.2463 22.7501 15.625 22.7501C15.0037 22.7501 14.5 23.2538 14.5 23.8751C14.5 24.4964 15.0037 25.0001 15.625 25.0001Z" fill="#143788" />
      </svg>
    </button>
    <span >
      <app-toolbar-menu>

      </app-toolbar-menu>
    </span>
    <button mat-icon-button class="home-button" (click)="navigateToRoute()">
      <mat-icon class="home-icon">home</mat-icon>
    </button>
  </div>


</nav>
<div class="testing-bar" *ngIf="isTesting">
  TESTING ENVIRONMENT
</div>

<div class="ui text" style="margin-top: 0em; ">
  <router-outlet></router-outlet>
</div>
