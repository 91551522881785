import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { Observable } from 'rxjs';
import { AuthService } from '../modules/shadow-rounding/shared/services/auth.service';


@Injectable()
export class RedirectGuard  {

  constructor(
    private authService: AuthService,
    private router: Router,
    private oktaAuth: OktaAuthGuard) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.oktaAuth.canActivate(next, state).then(isAuthorized => {
      if (isAuthorized) {
        return true;
      } else {
        window.location.href = 'https://teamhealth.samanage.com/catalog_items/1419257-okta-report-form/service_requests/new.portal?sso_token=8ae58d2c99f8517a7d6502a0ae5ca9463242e57b';
        return false;
      }
    })
  }
}
