import { Component, Inject, Injectable, InjectionToken } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthState, OktaAuth, TokenResponse } from '@okta/okta-auth-js';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtModel } from '../../../../models/jwt-model';
import { Tokens } from '../../../../models/tokens';
import { AuthorizedRefreshViewModel } from '../../../../models/authorized-refresh-view-model';
import { StringUtilsService } from '../../../../services/string-utils.service';
import { BaseDataService } from '../../../../services/base-data-service';
import { GenerateUrlService } from '../../../../services/base-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseDataService {

  static readonly accessTokenKey: string = "uf.accessToken";
  static readonly refreshTokenKey: string = "uf.refreshToken";

  public endpoint: string = "api/accounts/";
  private getTokenApiPath: string = this.endpoint + "tokens";
  private refreshTokensApiPath: string = this.endpoint + "refresh-tokens";
  private authState: AuthState;
  private refreshTokenTimeouter: any;
  private email: string = '';

  private _userInformation: BehaviorSubject<AuthState> = new BehaviorSubject<AuthState>(null);
  public userInformation$: Observable<AuthState> = this._userInformation.asObservable();
  private hasLoggedIn: boolean = false;
  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private stringUtilsService: StringUtilsService,
    public authService: OktaAuthStateService,
    public router: Router,
    private httpclient: HttpClient, private generateUrlService: GenerateUrlService) {
    super(httpclient);
    generateUrlService.setApplicationUrl();
    this.endpoint = generateUrlService.getHostUrl() + '/accounts';
    this.getTokenApiPath = this.endpoint + "/tokens";
    this.refreshTokensApiPath = this.endpoint + "/refresh-tokens";
    authService.authState$.subscribe((auth: AuthState) => {
      this.authState = auth;
      this.userInformation$ = this.authService.authState$;
      if (this.authState.isAuthenticated) {
        let claims = this.authState?.idToken?.claims;
        this.email = claims?.preferred_username;
        this.authenticate('');
      }
    });
  }
  private get refreshToken(): string {
    return localStorage.getItem(AuthService.refreshTokenKey);
  }

  private _jwt: BehaviorSubject<JwtModel> = new BehaviorSubject<JwtModel>(new JwtModel(localStorage.getItem(AuthService.accessTokenKey)));
  public jwt$: Observable<JwtModel> = this._jwt.asObservable();

  private setJwtModel(): boolean {
    let token = localStorage.getItem(AuthService.accessTokenKey);
    let jwtModel = new JwtModel(token);

    this._jwt.next(jwtModel); // updates to all subscribers

    return jwtModel.isAuthenticated();
  }

  public isAuthenticated(): boolean {
    let thereIsToken = localStorage.getItem(AuthService.accessTokenKey) != null;
    let isAuthenticated: boolean = this._jwt.value.isAuthenticated();

     if (!thereIsToken) {
      this.clearJwtModel();
    }

    return isAuthenticated && thereIsToken;
  }

  async authenticate(redirect: string): Promise<boolean> {
    this.logInfo("attempting to authenticate");

    try {
      
        let idToken = this.authState.idToken;
        if (idToken &&
          this.stringUtilsService.isSane(idToken.idToken)) {
          this.clearJwtModel();

          let headers: HttpHeaders = new HttpHeaders();
          headers = headers.append("Authorization", "Bearer " + idToken.idToken.trim());
          //this.logDebug(headers.get("Authorization"));
          return (await this.Get<Tokens>(this.getTokenApiPath + `/${this.email}`, headers))
            .toPromise()
            .then(
              (result: Tokens) => {
                if (!result) {
                  return false;
                }

                this.setTokensAndJwtModel(result);
                return true;
              },
              (error) => { throw new Error(error); }
            ).catch(err => {
              throw new Error(err?.message || err);
            });
        } 
    }
    catch (error) {
      this.logError(`Error while calling ${this.getTokenApiPath}: ${error}`);
      return false;
    }
  }

  async login() {
    await this.oktaAuth.signInWithRedirect({ originalUri: '' });
  }

  private clearJwtModel(): void {
    this._jwt.next(new JwtModel(""));
  }

  logout() {
    this.stopRefreshTokensTimeouter();
    localStorage.clear();
    this.clearJwtModel();
    this.router.navigate(['/login']);
  }

  private setTokensAndJwtModel(tokens: Tokens) {
    if (!tokens) {
      this.logWarn("tokens was falsy");
      return;
    }

    if (!tokens.accessToken) {
      this.logWarn("tokens.accessToken was falsy");
      return;
    }
    if (this.stringUtilsService.isNullOrWhiteSpace(tokens.accessToken)) {
      this.logWarn("tokens.accessToken was null, or empty, or whitespace");
      return;
    }

    localStorage.setItem(AuthService.accessTokenKey, tokens.accessToken);
    localStorage.setItem(AuthService.refreshTokenKey, tokens.refreshToken);

    // get new jwt model since tokens are new
    let isAuthenticated = this.setJwtModel();
    //if (isAuthenticated) {
    //  this.startRefreshTokensTimeouter();
    //}
  }

  // used when user is already authenticated on load,
  // and the authenticate() is not called and therefore doesn't fire startRefreshTokensTimeouter()
  //private checkRefreshTokenTimer() {
  //  if (!this.refreshTokenTimeouter) {
  //    this.startRefreshTokensTimeouter();
  //  }
  //}

  //private startRefreshTokensTimeouter() {
  //  this.logInfo("starting refresh-tokens-timeouter");

  //  //Note: getTime() method returns the number of milliseconds since the Unix Epoch
  //  const exp = this._jwt.value.expires;
  //  const now = new Date();
  //  const timeout_ms = exp.getTime() - now.getTime() - JwtModel.expirationOffsetMilliseconds;
  //  this.refreshTokenTimeouter = setTimeout(async () => {
  //    this.logInfo("refresh-tokens-timeouter timed-out");
  //    await this.refreshTokens();
  //  }, timeout_ms);

  //  this.logInfo("started refresh-tokens-timeouter");
  //  //this.logDebug({
  //  //    Expires: exp.toLocaleString(),
  //  //    Now: now.toLocaleString(),
  //  //    Offset: `${JwtModel.expirationOffsetMilliseconds} ms`,
  //  //    Timeout: `${timeout_ms} ms (approx. ${(new Date(now.getTime() + timeout_ms)).toLocaleString()})`
  //  //});
  //}

  private stopRefreshTokensTimeouter() {
    this.logInfo("stopping refresh-tokens-timeouter");

    clearTimeout(this.refreshTokenTimeouter);

    this.logInfo("stopped refresh-tokens-timeouter");
  }

  //private async refreshTokens() {
  //  this.logInfo("attempting to refresh-tokens");
  //  try {
  //    (await this.httpclient.post<Tokens>(this.refreshTokensApiPath,
  //      AuthorizedRefreshViewModel.from(this.refreshToken)))
  //      .toPromise()
  //      .then(
  //        (result: Tokens) => this.setTokensAndJwtModel(result),
  //        (error) => { throw new Error(error); }
  //      ).catch(err => {
  //        throw new Error(err?.message || err);
  //      });
  //  }
  //  catch (error) {
  //    this.clearJwtModel();
  //    this.logError(`Error while calling ${this.refreshTokensApiPath}: ${error}`);
  //  }
  //}

  private logError(message: any) {
    console.error(`${this.getTimestamp()}: ${message}`);
  }

  private logWarn(message: any) {
    console.warn(`${this.getTimestamp()}: ${message}`);
  }

  private logInfo(message: any) {
    console.info(`${this.getTimestamp()}: ${message}`);
  }

  private logDebug(message: any) {
    console.debug(`${this.getTimestamp()}: ${message}`);
  }

  private getTimestamp(): string {
    return new Date().toLocaleString('en-us',
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
  }

  getUserProfile(accessToken: string, url: string) {
    var headers = new HttpHeaders();
    headers = headers.append("Authorization", `Bearer ${accessToken}`)
    return this.Get(url, headers);
  }

  public async writeTokens() {
    combineLatest(from(this.oktaAuth.tokenManager.get('accessToken')), from(this.oktaAuth.token.parseFromUrl()))
      .subscribe(accessToken => {
        if (accessToken[1]) {
          this.setTokens(accessToken[1]);
          if (this.hasLoggedIn == false) {
            this.hasLoggedIn = true;
            this.router.navigate(['/']);
          }
        }
      }, error => {
        this.handleError(error);
      })
  }

  handleError(error) {
    if (error.errorCode === "access_denied") {
      window.location.href = 'https://teamhealth.samanage.com/catalog_items/1419257-okta-report-form/service_requests/new.portal?sso_token=8ae58d2c99f8517a7d6502a0ae5ca9463242e57b';
    } else {
    }
  }

  setTokens(tokens: TokenResponse) {
    this.oktaAuth.tokenManager.setTokens(tokens.tokens);
  }
}

export const _OKTA_AUTH = new InjectionToken<OktaAuth>('OktaAuth');

