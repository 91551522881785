import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { MatMenu } from '@angular/material/menu';
import { filter } from 'rxjs';
import { HeaderFilterEnum } from '../shared/header-filter.enum';

@Component({
  selector: 'app-toolbar-menu',
  templateUrl: './toolbar-menu.component.html',
  styleUrl: './toolbar-menu.component.css',
  exportAs: 'menuInOtherComponent'
})
export class ToolbarMenuComponent  {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  @Input() matMenu: MatMenu;
  filter = HeaderFilterEnum;
  menuComponent: string = '';
  constructor(headerService: HeaderService) {
    headerService.headerMenuComponent$
      .subscribe(x => {
        this.menuComponent = this.filter[x];
      })
  }
}

