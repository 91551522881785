import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Facility } from '../models/facility';
import {FormControl } from '@angular/forms';

@Component({
  selector: 'app-facility-selector',
  templateUrl: './facility-selector.component.html',
  styleUrls: ['./facility-selector.component.css']
})
export class FacilitySelectorComponent implements OnInit {
  @ViewChild('facilityNameInput') input: ElementRef<HTMLInputElement>;

  facilityControl = new FormControl('');

  @Input()
  facilities: Facility[];

  filteredFacilities: Facility[];

  @Output()
  selectedFacilityEvent: EventEmitter<Facility> = new EventEmitter<Facility>();

  public facilityFilter: Facility;

  constructor() {
  }

  ngOnInit(): void {
    this.filteredFacilities = this.facilities.slice();
  }

  displayFn(facility: Facility): string {
    return (facility && facility.name) ? facility.name : '';
  }

  selectFacility(selectedFacility: Facility) {
    this.selectedFacilityEvent.emit(selectedFacility);
  }

  searchFacilities(value: string): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredFacilities = this.facilities.filter(facility => facility.name.toString().includes(filterValue));
  }
  clearSelection(): void {
    this.facilityControl.setValue(''); // Set the value to an empty string
  }
}
