import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnTableModel } from '../shared/column-table-model';

@Component({
  selector: 'uf-table',
  templateUrl: './universal-table.component.html',
  styleUrls: ['./universal-table.component.scss']
})

export class UniversalTableComponent {
  tableData: any;
  tableColumns: TableDataSource = null;
  tableColumnNames: string[] = [];
  headerStyle: string = '';
  rowStyle: string = '';
  editableColumns: string[] = [];

  savedValues = [];
  @Output() saveEmitter: EventEmitter<any> = new EventEmitter<any>();

  //TODO: Create ability to have readonly columns
  @Input() set data(value: TableDataSource) {
    if (value.rowData) {
      this.tableColumns = null;
      this.tableColumnNames = [];
      this.tableColumnNames = value.columnNames;
      this.tableData = new MatTableDataSource(value.rowData);
    }
  };

  @Input() set headerStyleClass(className: string) {
    this.headerStyle = className;
  };

  @Input() set rowStyleClass(className: string) {
    this.rowStyle = className;
  };

  constructor() { }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableData.filter = filterValue.trim().toLowerCase();
  }

  updateValue(item, column, value) {
    this.saveEmitter.emit({ column: column, value: value.target.value, row: item.Description.value });
  }

  saveValues() {
    this.saveEmitter.emit(this.savedValues);
  }
  getElementData(source, column) {
    return source.find(x => x.column == column).data;
  }
  getReadonly(source, column) {
    return true;
    ;
  }
  private capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

// TODO: Make these their own models.  Keeping here for now until we figure out the right way to do it.
export class TableDataSource {
  columnNames: string[];
  rowData: any[]
;

}

export class RowDataSource {
  value: any;
  isReadonly: boolean;
}

export class CellDataSource {
  data: string;
  column: string;
  isReadonly: boolean;
}

interface Dictionary<T> {
  [Key: string]: T;
}
