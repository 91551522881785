import { Injectable } from '@angular/core';
import { AuthState } from '@okta/okta-auth-js';
import { BehaviorSubject, filter, take } from 'rxjs';
import { AdministrationDetails } from '../models/administration-details';
import { AdministrationItem } from '../models/administration-item';
import { FeatureFlag } from '../models/feature-flag';
import { AuthService } from '../modules/shadow-rounding/shared/services/auth.service';
import { AdminDataService } from './admin-data.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private email: string;
  public featureFlags: BehaviorSubject<FeatureFlag[]> = new BehaviorSubject<FeatureFlag[]>([]);
  public adminData: BehaviorSubject<AdministrationDetails> = new BehaviorSubject<AdministrationDetails>(null);
  constructor(
    private authService: AuthService,
    private adminDataService: AdminDataService

  ) {
    this.authService.userInformation$
      .pipe(
        filter(x => !!x?.idToken?.claims.name),
        take(1))
      .subscribe((authState: AuthState) => {
        let claims = authState?.idToken?.claims;
        this.email = claims?.preferred_username;
        this.getAdministrationData().subscribe(x => {
          this.adminData.next(x);
        });
        this.getFeatureFlags().subscribe(x => {
          this.featureFlags.next(x);
        })
      });

  }

  public getAdministrationData() {
    return this.adminDataService.getAdminData(this.email);
  }

  public getFeatureFlags() {
    return this.adminDataService.getFeatureFlags(this.email);
  }

  public updateFeatureFlag(flag: FeatureFlag) {
    return this.adminDataService.setFeatureFlag(flag, this.email);
  }

  public updateAdminItems(items: AdministrationItem[]) {
    return this.adminDataService.updateAdminItems(items, this.email);
  }

}
