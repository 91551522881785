import { Injectable } from '@angular/core';
import { PacInteractiveDataService } from './pac-interactive-data.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Facility } from 'spa/app/modules/shared/models/facility';
import { PACUserResponse } from '../../../shared/models/pac-user-response';
import { PatientDetails } from 'spa/app/modules/shared/models/patient-details';
import { FacilityPatientDetails } from '../models/FacilityPatientDetail';


@Injectable({
  providedIn: 'root'
})
export class PacInteractiveService {
  constructor(private pacInteractiveService: PacInteractiveDataService) { }

  public dueCount$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  public selectedFacility$: BehaviorSubject<Facility> = new BehaviorSubject<Facility>(null);
  public physicianNPI$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public patientFilter: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])
  public groupName: string = '';
  public selectedPatient: BehaviorSubject<PatientDetails> = new BehaviorSubject<PatientDetails>(null);

  getFacilitiesForPhysician(physicianId: number): Observable<Facility[]> {
    return this.pacInteractiveService.getFacilitiesForPhysician(physicianId);    
  }

  searchFacilities(groupName: string, query: string): Observable<Facility[]> {
    return this.pacInteractiveService.searchFacilities(groupName, query);
  }

  getPhysicianNPI(email: string): Observable<number> {
    return this.pacInteractiveService.getNPIForPhysicianByEmail(email).pipe(
      tap(x => this.updateNPI(x)));
  }
  
  getFacilitiesForPhysicianByEmail(email: string): Observable<FacilityPatientDetails[]> {
    return this.pacInteractiveService.getFacilitiesForPhysicianByEmail(email).pipe(
      tap(x => this.getCurrentDueCounts(x)),
      tap(x => this.getGroupName(x)));
  }

  getPatientsByFacility(facilityId: number): Observable<PatientDetails[]> {
    return this.pacInteractiveService.getPatientsByFacility(facilityId);
  }

  getPatientsDetailsById(patientId: number): Observable<PatientDetails> {
    return this.pacInteractiveService.getPatientsDetailsById(patientId);
  }

  savePatientDetails(userResponses: PACUserResponse[]): Observable<PatientDetails> {
    return this.pacInteractiveService.savePatientDetails(userResponses);
  }

  getUserResponseByInteractiveWorksheetPatientsId(interactiveWorksheetPatients_Id: number): Observable<PACUserResponse[]> {
    return this.pacInteractiveService.getUserResponseByInteractiveWorksheetPatientsId(interactiveWorksheetPatients_Id);
  }

  private getCurrentDueCounts(details: FacilityPatientDetails[]) {
    let facilities = details.map(x => x.facility);
    let dueCount = 0;
    facilities.forEach(x => dueCount += x.dueCount);
    this.updateDueCount(dueCount);
    return details;
  }

  private getGroupName(details: FacilityPatientDetails[]) {
    let facilities = details.map(x => x.facility);
    let groupName = facilities.map(x => x.groupName).find(y => !!y);
    this.groupName = groupName;
  }

  updateDueCount(dueCount: number) {
    this.dueCount$.next(dueCount);
  }

  updateSelectedFacility(fac: Facility) {
    this.selectedFacility$.next(fac);
  }

  updateNPI(npi: number) {
    this.physicianNPI$.next(npi);
  }

  updatePatientFilter(filters: string[]) {
    this.patientFilter.next(filters);
  }

  selectPatient(detail: PatientDetails) {
    this.selectedPatient.next(detail);
  }
}
