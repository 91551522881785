import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

class AutoWrapperResponse<T> {
    result: T;
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class BaseDataService {
    constructor(private http: HttpClient) { }

    public Get<T>(endpoint: string, httpHeaders: HttpHeaders = null): Observable<T> {
        let options = {
            headers: httpHeaders
        };

        return this.http.get<T>(endpoint, options);
    }

    public Post<T>(endpoint: string, data: any): Observable<T> {
        return this.http.post<T>(endpoint, data);
    }

    public Put<T>(endpoint: string, data: any, httpParams: HttpParams = null): Observable<T> {
        return this.http.put<T>(endpoint, data, { params: httpParams });
    }

    public Delete<T>(endpoint: string): Observable<T> {
        return this.http.delete<T>(endpoint);
    }
}
