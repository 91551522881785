export class Form {
    id: number;
    name: string;
    isPublisFhed: boolean;
    publishDate: Date;
    passingScore: number; 
    isRepeatable: boolean; 
    version: number;
    urlRoute: string;
    formDescription: string;
}
