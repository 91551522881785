import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { environment } from 'spa/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) { }

  private readonly analyticsUrl: string = 'THAnalytics/UniversalForm'
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.oktaconfigvalues.issuer)) {
      return next.handle(req);
    }
    const accessToken = this.oktaAuth.getAccessToken();
    let headers: HttpHeaders = req.headers;

    headers = req.headers.append('Authorization', 'Bearer ' + accessToken);
    
    var apiReq: HttpRequest<any>;
    if (req.url.includes(this.analyticsUrl)) {
      headers = headers.set("Content-Type", `['application/json', 'text/plain']`)
      apiReq = req.clone({ url: `${environment.ANALYTICS_URL}`, headers: headers });

    } else {
      apiReq = req.clone({ url: `${req.url}`, headers: headers });
    }

    return next.handle(apiReq);
  }
}
