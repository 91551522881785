<ng-container *ngIf="menuComponent == this.filter.PacPatientFilter">
  <button mat-icon-button class="button-style" [matMenuTriggerFor]="headerMenu">
    <mat-icon class="icon-style">tune</mat-icon>
  </button>
  <mat-menu #headerMenu>
    <app-patient-filter></app-patient-filter>
  </mat-menu>
</ng-container>


