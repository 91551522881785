import { Inject, Injectable } from "@angular/core";

@Injectable()
export class GenerateUrlService {

  hostname = window.location.hostname;
  serviceurl?: string;
  public applicationBaseUrl?: string = "";

  constructor() { }

  // TODO: Clean this up to use environment variables instead of relying on window.location.hostname
  public getHostUrl(): string {
    if (this.getLocalEnvironment()) {
      return 'https://localhost:44314'
    }
    else if (this.getDevEnvironment()) {
      //return 'https://api-dev-universalform.s3universalform.it-dev.tmhlabs.net';
      return 'https://pz5u6ps0vh.execute-api.us-east-1.amazonaws.com/Prod';

    } else if (this.getTestEnvironment()) {
      //return 'https://test-universalform.it-dev.tmhlabs.net';
      return 'https://21ptjof582.execute-api.us-east-1.amazonaws.com/Prod';
    } else if (this.getProdEnvironment()) {
      //return prod URL?;
      return 'https://yvbexoxhz5.execute-api.us-east-1.amazonaws.com/Prod';
    }
  }

  public setApplicationUrl() {
    this.serviceurl = this.getHostUrl();

    this.applicationBaseUrl = this.getHostUrl() + "/";

  }

  public getLocalEnvironment() {
    return this.hostname.includes("local")
  }

  public getDevEnvironment() {
    return this.hostname.includes("s3universalform")
  }

  public getTestEnvironment() {
    return this.hostname.includes("test-universalform")
  }

  public getProdEnvironment() {
    return this.hostname.includes("universalform.teamhealth.com");
  }


}
