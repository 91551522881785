import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseDataService } from "../../../../services/base-data-service";
import { Facility } from "../../../shared/models/facility";
import { Role } from "../models/role";
import { ServiceLine } from "../models/service-line";
import { GenerateUrlService } from "../../../../services/base-service";
import { ClinicianDetails } from '../models/clinician-details';
import { FormQuestions } from "../../../../models/form-questions";
import { Form } from "../../../../models/form";

@Injectable({
  providedIn: 'root'
})
export class ShadowRoundingDataService extends BaseDataService {

  private endpoint: string = '/api'

  constructor(
    private httpclient: HttpClient,
    private generateUrlService: GenerateUrlService) {
    super(httpclient);
    generateUrlService.setApplicationUrl();
    this.endpoint = generateUrlService.getHostUrl();
  }

  getServiceLines(phyId: number): Observable<ServiceLine[]> {
    let endpoint = `${this.endpoint}/physician/${phyId}/service-lines`;
    return this.Get<ServiceLine[]>(endpoint);
  }

  getFacilitiesForUser(phyId: number, serviceLineId: number): Observable<Facility[]> {
    return this.Get<Facility[]>(`${this.endpoint}/physician/${phyId}/service-lines/${serviceLineId}/facilities`);
  }

  getRolesForUser(serviceLineId: number = 0): Observable<Role[]> {
    return this.Get<Role[]>(`${this.endpoint}/service-lines/${serviceLineId}/roles`);
  }

  getClinicians(query: string) {
    return this.Get<ClinicianDetails[]>(`${this.endpoint}/clinicians?query=${query}`);
  }

  getClinicianRoles(serviceLineId: number, facilityId: number, clinicianId: number) {
    return this.Get<any[]>(`${this.endpoint}/service-lines/${serviceLineId}/facilities/${facilityId}/clinician/${clinicianId}`);    
  }

  getQuestionnaire(serviceLineId: number, formId: number) {
    return this.Get<FormQuestions[]>(`${this.endpoint}/service-lines/${serviceLineId}/forms/${formId}/questionnaire`);
  }

  getPublishedQuestionnaireByUrlRoute(serviceLineId: number, urlRoute: string) {
    return this.Get<FormQuestions[]>(`${this.endpoint}/service-lines/${serviceLineId}/urlRoute/${urlRoute}/questionnaire`);
  }

  updateQuestionnaire(email: string, questionnaire: any) {
    return this.Post(`${this.endpoint}/email/${email}/questionnaire`, questionnaire);
  }

  submitQuestionnaire(questionResponse: any) {
    return this.httpclient.post(`${this.endpoint}/submitQuestionnaire/questionResponse`, questionResponse);
  }

  sendAdditionalEmail(questionResponse: any) {
    return this.httpclient.post(`${this.endpoint}/sendAdditionalEmail/questionResponse`, questionResponse);
  }

  getFormDetailsByUrlRoute(urlRoute: string) {
    return this.Get<Form>(`${this.endpoint}/urlRoute/${urlRoute}/form`);
  }
}
