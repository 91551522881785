<div *ngIf="(facilities && (facilities.length > 0))">
    <mat-form-field class="facility-selector">
        <mat-label>Select Facility</mat-label>
        <input #facilityNameInput
               type="Select Facility"
               placeholder="Pick one"
               matInput [formControl]="facilityControl"
               [matAutocomplete]="auto"
               (input)="searchFacilities()"
               (focus)="searchFacilities()">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" requireSelection (optionSelected)="selectFacility($event.option.value)">
            <mat-option *ngFor="let facility of filteredFacilities" [value]="facility">
                {{ facility.name }}
            </mat-option>
        </mat-autocomplete>
        <button mat-icon-button matSuffix aria-label="Clear" *ngIf="facilityControl.value" (click)="clearSelection()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    {{facilityFilter}}
</div>
