import { HttpClient } from "@angular/common/http";
import {  Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Facility } from 'spa/app/modules/shared/models/facility';
import { BaseDataService } from 'spa/app/services/base-data-service';
import { GenerateUrlService } from 'spa/app/services/base-service';
import { PACUserResponse } from '../../../shared/models/pac-user-response';
import { PatientDetails } from 'spa/app/modules/shared/models/patient-details';
import { FacilityPatientDetails } from "../models/FacilityPatientDetail";

@Injectable({
  providedIn: 'root'
})
export class PacInteractiveDataService extends BaseDataService  {
  private endpoint: string = '/api'

  constructor(
    private httpclient: HttpClient,
    private generateUrlService: GenerateUrlService) {
    super(httpclient);
    this.generateUrlService.setApplicationUrl();
    this.endpoint = this.generateUrlService.getHostUrl();
  }

  getNPIForPhysicianByEmail(email: string): Observable<number> {
    return this.httpclient.get<number>(`${this.endpoint}/physician/email/${email}/npi`);
  }

  getFacilitiesForPhysician(phyId: number): Observable<Facility[]> {
    return this.httpclient.get<Facility[]>(`${this.endpoint}/physician/${phyId}/facilities`);
  }

  searchFacilities(groupName: string, query: string): Observable<Facility[]> {
    return this.httpclient.get<Facility[]>(`${this.endpoint}/physician/group/${groupName}/facilities?query=${query}`);
  }

  getFacilitiesForPhysicianByNPI(npi: number): Observable<Facility[]> {
    return this.httpclient.get<Facility[]>(`${this.endpoint}/physician/npi/${npi}/facilities`);
  }
  
  getFacilitiesForPhysicianByEmail(email: string): Observable<FacilityPatientDetails[]> {
    return this.httpclient.get<FacilityPatientDetails[]>(`${this.endpoint}/physician/email/${email}/facilities`);
  }

  getPatientsByFacility(facilityId: number): Observable<PatientDetails[]> {
    return this.httpclient.get<PatientDetails[]>(`${this.endpoint}/facility/facilityId/${facilityId}/patients`);
  }

  getPatientsDetailsById(patientId: number): Observable<PatientDetails> {
    return this.httpclient.get<PatientDetails>(`${this.endpoint}/patient/patientId/${patientId}/patientdetails`);
  }

  savePatientDetails(userResponses: PACUserResponse[]): Observable<PatientDetails> {
    return this.httpclient.post<PatientDetails>(`${this.endpoint}/patient/savePatientDetails`, userResponses);
  }

  getUserResponseByInteractiveWorksheetPatientsId(interactiveWorksheetPatients_Id: number): Observable<PACUserResponse[]> {
    return this.httpclient.get<PACUserResponse[]>(`${this.endpoint}/patient/interactiveWorksheetPatients_Id/${interactiveWorksheetPatients_Id}/getUserResponse`);
  }
}
