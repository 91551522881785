import { Component } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { MatMenuItem } from '@angular/material/menu';
import { PacInteractiveService } from '../shared/services/pac-interactive.service';

@Component({
  selector: 'app-patient-filter',
  templateUrl: './patient-filter.component.html',
  styleUrls: ['./patient-filter.component.css'],
  exportAs: 'menuInOtherComponent',
  providers: [{ provide: MatMenuItem, useExisting: PatientFilterComponent }],
})
export class PatientFilterComponent {

  overdue= true;
  twoWeeks= true;
  notDue= true;
  AWE= true;
  ACP= true;
  FMV= true;

  constructor(private pacInteractiveService: PacInteractiveService) {
    this.pacInteractiveService.updatePatientFilter(['overdue', 'twoWeeks', 'notDue', 'AWE', 'ACP', 'FMV']);
  }

  updateFilter(e, i: MatSelectionList, j: MatSelectionList) {
    // stopPropagation will prevent the menu from closing after one selection.
    e.stopPropagation();

    var list = i.selectedOptions.selected.map(x => x.value);
    var list2 = j.selectedOptions.selected.map(x => x.value);
    var fullList = list.concat(list2);
    this.pacInteractiveService.updatePatientFilter(fullList);
  }
}

export interface PACFilterModel {
  overdue: boolean,
  twoWeeks: boolean,
  notDue: boolean,
  AWE: boolean,
  ACP: boolean,
  FMV: boolean
}
