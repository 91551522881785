import * as jwt_decode from 'jwt-decode';
import { ClaimTypes, ModuleAccessValues } from './claim-types.enum';

export class JwtModel {

  private static _expirationOffsetMinutes: number = 5;
  static get expirationOffsetMinutes(): number {
    return JwtModel._expirationOffsetMinutes;
  }
  static get expirationOffsetSeconds(): number {
    return JwtModel.expirationOffsetMinutes * 60;
  }
  static get expirationOffsetMilliseconds(): number {
    return JwtModel.expirationOffsetSeconds * 1000;
  }

  private claimMap: Map<string, Array<string>>;

  constructor(jwt: string) {

    this.claimMap = new Map<string, Array<string>>();

    if (jwt && (jwt.trim().length > 0)) {
      let decodedJwt = jwt_decode.jwtDecode(jwt);

      for (let key in decodedJwt) {
        if (decodedJwt.hasOwnProperty(key)) {
          if (!this.claimMap.has(key)) {
            this.claimMap.set(key, new Array<string>());
          }
          this.claimMap.set(
            key,
            this.claimMap.get(key).concat(decodedJwt[key]));
        }
      }
    }

    return this;
  }

  getClaimValues(claimType: ClaimTypes): Array<string> {
    if (this.claimMap.has(claimType)) {
      return this.claimMap.get(claimType);
    }
    return new Array<string>();
  }

  getClaimValue(claimType: ClaimTypes): string {
    let claimValues = this.getClaimValues(claimType);
    if (claimValues.length > 0) {
      return claimValues[0];
    }
    return null;
  }

  getClaimValueNumber(claimType: ClaimTypes): number {
    let claimValue = this.getClaimValue(claimType);
    if (claimValue) {
      let num = parseInt(claimValue);
      if (!Object.is(NaN, num)) {
        return num;
      }
    }
    return null;
  }

  getClaimValueDate(claimType: ClaimTypes): Date {
    let num = this.getClaimValueNumber(claimType);
    if (num) {
      let retVal = new Date(0);
      retVal.setUTCSeconds(num);
      return retVal;
    }
    return null;
  }

  get jti(): string {
    return this.getClaimValue(ClaimTypes.Jti);
  }

  get issuer(): string {
    return this.getClaimValue(ClaimTypes.Issuer);
  }

  get notBefore(): Date {
    return this.getClaimValueDate(ClaimTypes.NotBefore);
  }

  get expires(): Date {
    return this.getClaimValueDate(ClaimTypes.Expiration);
  }

  get authenticationMethod(): string {
    return this.getClaimValue(ClaimTypes.AuthenticationMethod);
  }

  get authenticationInstant(): Date {
    let claimVal = this.getClaimValue(ClaimTypes.AuthenticationInstant);
    if (claimVal) {
      return new Date(claimVal);
    }
    return null;
  }

  get userId(): number {
    return this.getClaimValueNumber(ClaimTypes.NameIdentifier);
  }

  get primaryEmail(): string {
    return this.getClaimValue(ClaimTypes.PrimaryEmail);
  }

  get userName(): string {
    return this.getClaimValue(ClaimTypes.Username);
  }

  get lastName(): string {
    return this.getClaimValue(ClaimTypes.LastName);
  }

  get firstName(): string {
    return this.getClaimValue(ClaimTypes.FirstName);
  }

  get roleNames(): string[] {
    return this.getClaimValues(ClaimTypes.Roles);
  }

  isVerified(): boolean {
    //TBD: validate the signature here
    return true;
  }

  isExpired(): boolean {
    if (!this.expires) {
      return true;
    }

    let boundaryDateTime = new Date(new Date().getTime() - JwtModel.expirationOffsetMilliseconds);
    return boundaryDateTime > this.expires;
  }

  isAuthenticated(): boolean {
    return this.isVerified() && !this.isExpired();
  }

  /**
   * Determines if all expected-claim-values are available for a given claim-type
   * @param claimType Claim type in which to search
   * @param expectedClaimValues Claim values to verify
   */
  hasClaims(claimType: ClaimTypes, expectedClaimValues: Array<string>): boolean {
    let claimValues = this.getClaimValues(claimType);
    if (claimValues.length <= 0) {
      return false;
    }

    for (let i = 0; i < expectedClaimValues.length; i++) {
      if (claimValues.indexOf(expectedClaimValues[i]) == -1) {
        return false;
      }
    }
    return true;
  }

  /**
   * Determines if any claim is available for a given claim-type
   * @param claimType Claim type in which to search
   * @param expectedClaimValue Claim value to verify
   */
  hasClaim(claimType: ClaimTypes, expectedClaimValue: string): boolean {
    return this.hasClaims(claimType, [expectedClaimValue]);
  }

  /**
   * Determines if any expected-claim-values are available for a given claim-type
   * @param claimType Claim type in which to search
   * @param expectedClaimValues Claim values to verify
   */
  hasClaimType(claimType: ClaimTypes): boolean {
    return this.claimMap.has(claimType);
  }

  /**
   * Determines if any expected-claim-values are available for a given claim-type
   * @param claimType Claim type in which to search
   * @param expectedClaimValues Claim values to verify
   */
  anyClaims(claimType: ClaimTypes, expectedClaimValues: Array<string>): boolean {
    let claimValues = this.getClaimValues(claimType);
    if (claimValues.length <= 0) {
      return false;
    }

    for (let i = 0; i < expectedClaimValues.length; i++) {
      if (claimValues.indexOf(expectedClaimValues[i]) != -1) {
        return true;
      }
    }
    return false;
  }

  /**
  * Checks for module accessibility, based on claims
  * @param module Module in question
  */
  canAccessModule(module: ModuleAccessValues): boolean {
    let hasAccess = this.hasClaim(ClaimTypes.Roles, module);

    if(hasAccess) {
      return hasAccess;
    };
    window.location.href = 'https://teamhealth.samanage.com/catalog_items/1419257-okta-report-form/service_requests/new.portal?sso_token=8ae58d2c99f8517a7d6502a0ae5ca9463242e57b';

  }

}
