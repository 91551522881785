import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HeaderItem } from "../models/header-item";
import { GenerateUrlService } from "./base-service";


@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private _headerTitle: BehaviorSubject<HeaderItem> = new BehaviorSubject<HeaderItem>
    ({
      display: "Universal Form",
      route: "/dashboard"
    });

  public headerTitle$: Observable<HeaderItem> = this._headerTitle.asObservable();

  private _navigate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public navigate$: Observable<boolean> = this._navigate.asObservable();

  private _displayNavigate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public displayNavigate$: Observable<boolean> = this._displayNavigate.asObservable(); 
  
  private _displayHelp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public displayHelp$: Observable<boolean> = this._displayHelp.asObservable();
    
  private _displayMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public displayMenu$: Observable<boolean> = this._displayMenu.asObservable();
  
  private _helpPath: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public helpPath$: Observable<string> = this._helpPath.asObservable();

  public _headerMenuComponent: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public headerMenuComponent$: Observable<any> = this._headerMenuComponent.asObservable();
  constructor(private urlService: GenerateUrlService) { }

  setHeaderTitle(name: string, route: string) {
    if (name != null || name.length > 0) {
      this._headerTitle.next({
        display: name,
        route
      });
    } else {
      this._headerTitle.next({
        display: "Universal Form",
        route: "/dashboard"
      });
    }
  }

  displayTestHeader(): boolean {
    return !this.urlService.getProdEnvironment();
  }

  onNavigation() {
    this._navigate.next(true);
    this._navigate.next(false);
  }

  displayNavigation(shouldDisplay: boolean) {
    this._displayNavigate.next(shouldDisplay);
  }

  displayHelp(shouldDisplay: boolean) {
    this._displayHelp.next(shouldDisplay);
  }
  
  displayMenu(shouldDisplay: boolean) {
    this._displayMenu.next(shouldDisplay);
  }

  setHelpPath(path: string) {
    this._helpPath.next(path);
  }

  setHeaderMenuComponent(item: any) {
    this._headerMenuComponent.next(item);
  }
}
