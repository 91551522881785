import { Injectable } from "@angular/core";
import { KeyValue } from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class StringUtilsService {

    isNullOrWhiteSpace(value: string): boolean {
        return value == null || value.replace(/\s/g, '').length < 1;
    }

    areMatches(firstValue: string, secondValue: string): boolean {
        return firstValue.localeCompare(secondValue, undefined, { sensitivity: 'accent' }) === 0;
    }

    isEqual(firstValue: string, secondValue: string) {
        if (this.isNullOrWhiteSpace(secondValue) || !this.areMatches(firstValue, secondValue)) {
            return false;
        }
        return true;
    }

    areEqual(firstArray: Array<string>, secondArray: Array<string>) {
        if (firstArray.length !== secondArray.length) { return false; }
        let uniqueValues = new Set([...firstArray, ...secondArray]);
        for (let v of uniqueValues) {
            let firstCount = firstArray.filter(e => e === v).length;
            let secondCount = secondArray.filter(e => e === v).length;
            if (firstCount !== secondCount) { return false; }
        }
        return true;
    }

    isSane(value: string): boolean {
        return value && !this.isNullOrWhiteSpace(value);
    }

    evaluateSanity(value: string, defaultValue: string, dontTrim: boolean = false): string {
        let retVal = this.isSane(value) ? value : defaultValue;
        return dontTrim ? retVal : retVal.trim();
    }

}

