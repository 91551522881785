import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthState } from '@okta/okta-auth-js';
import { AuthService } from './modules/shadow-rounding/shared/services/auth.service';
import { GlobalService } from './modules/shared/global.service';
import { HeaderService } from './services/header.service';
@Component({

  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'Universal Form';
  route = "/dashboard"
  isAuthenticated!: boolean;
  public authState: AuthState;
  isTesting: boolean = false;
  displayNavigation: boolean = false;
  displayHelp: boolean = false;
  displayMenu: boolean = false;
  helpPath: string = '';

  constructor(
    private authService: AuthService,
    private headerService: HeaderService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private globalService: GlobalService) {

  }

  async ngOnInit(): Promise<void> {
    this.authService.writeTokens();

    this.authService.userInformation$.subscribe(authState => {
      this.authState = authState;
    });

    this.headerService.headerTitle$.subscribe(header => {
      this.title = header.display;
      this.cdr.detectChanges();
    });

    this.headerService.displayNavigate$.subscribe(display => {
      this.displayNavigation = display;
      this.cdr.detectChanges();
    });

    this.headerService.displayHelp$.subscribe(display => {
      this.displayHelp = display;
      this.cdr.detectChanges;
    });
    
    this.headerService.displayMenu$.subscribe(display => {
      this.displayMenu = display;
      this.cdr.detectChanges;
    });

    this.headerService.helpPath$.subscribe(path => {
      this.helpPath = path;
    });

    this.isTesting = this.headerService.displayTestHeader();
  }
  public login(): void {
    this.authService.login();
  }

  public logout(): void {
    this.authService.logout();
  }

  public navigate(): void {
    this.headerService.onNavigation();
  }

  renderMenu() {
    this.displayMenu = true;
  }

  navigateToRoute() {
    this.globalService.popConfirmDialog(`Are you sure you want to return to Universal Form?`)
      .toPromise()
      .then(result => {
        if (result === true) {
          this.headerService.displayMenu(false);
          this.headerService.setHeaderMenuComponent(null);
          var date = new Date().getTime();
          this.headerService.setHeaderTitle("Universal Form", "/dashboard");
          this.headerService.displayHelp(false);
          this.router.navigate([this.route], {
            queryParams: {
              timeStamp: date
            }
          });
        } 
      });
  }

  openPDF() {
    var route = this.helpPath;
    window.open(route, "_blank");
    return false;
  }
}
