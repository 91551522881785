<table mat-table [(dataSource)]="tableData" >

  <ng-container *ngFor="let column of tableColumnNames">
    <ng-container [matColumnDef]="column" [style]="rowStyle">
      <th [style]="headerStyle" mat-header-cell *matHeaderCellDef> <b>{{capitalizeFirstLetter(column)}}</b> </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element[column]?.isReadonly" [ngClass]='element[column]?.rowStyle' >
          {{element[column]?.value}}
        </div>
        <ng-container *ngIf="!element[column]?.isReadonly">
          <input matInput class="input" (keyup)="updateValue(element, column, $event)"
                 [value]="element[column]?.value" [type]="element[column]?.inputType">
        </ng-container>
      </td>
     

    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="tableColumnNames"></tr>
  <tr mat-row *matRowDef="let row; let odd = index; columns: tableColumnNames;" [ngClass]="{'blank-row': row?.Description?.rowStyle == 'blank-row' , 'odd-row': odd % 2}"></tr>
</table>
