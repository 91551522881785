import { Component, HostListener, Input, OnInit } from '@angular/core';
import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import { ChartDataPoint } from '../shared/chart-data-point';
import { ChartDataSource } from '../shared/chart-datasource';

@Component({
  selector: 'uf-waterfall-chart',
  templateUrl: './waterfall-chart.component.html',
  styleUrls: ['./waterfall-chart.component.css']
})
export class WaterfallChartComponent implements OnInit {
  chartValues: ChartDataPoint[] = [];
  @Input() set dataSource(data: ChartDataSource) {
    if (!!data && data.data && data.data.length > 0) {
      this.chartValues = data.data;
      this.positiveBarColor = data.positiveBarColor;
      this.negativeBarColor = data.negativeBarColor;
      this.positiveNegativeBreakpoint = data.posNegDiff;
      this.positiveIcon = data.positiveIcon;
      this.negativeIcon = data.negativeIcon;
      this.initChart();
    }
  }

  private positiveBarColor: string = "steelblue";
  private negativeBarColor: string = "red";
  private positiveIcon: string = '\uf062';
  private negativeIcon: string = '\uf063';
  private positiveNegativeBreakpoint: number = 0;

  private getScreenWidth: number;
  private width: number;
  private height: number;
  private margin = { top: 20, right: 20, bottom: 20, left: 40 };
  private padding = { bottom: 10, top: 10 };
  private x: any;
  private y: any;
  private svg: any;
  private g: any;

  constructor() { }

  ngOnInit() {
    this.getScreenWidth = window.innerWidth
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.initChart();
  }

  private initChart() {
    this.initSvg();
    this.initAxis();
    this.drawAxis();
    this.drawWaterfall();
  }

  private initSvg() {
    d3.selectAll("svg > *").remove();
    this.svg = d3.select('.chart');
    this.width = +this.getScreenWidth - this.margin.left - this.margin.right;
    this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;

    this.g = this.svg.append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);
  }

  private initAxis() {
    this.x = d3Scale
      .scaleBand()
      .rangeRound([0, this.width])
      .padding(0.1);

    const maxAbsValue = d3Array.max(this.chartValues, d => Math.abs(d.yValue));
    const yDomainMax = maxAbsValue * 1.25;
    const yDomainMin = maxAbsValue * -1.25;

    this.y = d3Scale
      .scaleLinear()
      .range([this.height - this.padding.bottom, this.padding.top])
      .domain([yDomainMin, yDomainMax]);

    this.x.domain(this.chartValues.map(d => d.xValue));
  }

  private drawAxis() {
    this.g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0,${this.height - this.padding.bottom})`)
      .call(d3Axis.axisBottom(this.x));

    this.g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3Axis.axisLeft(this.y).ticks(10))
      .append('text')
      .attr('class', 'axis-title')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .text('yValue');

    this.g.append('line')
      .attr('class', 'zero-line')
      .attr('x1', 0)
      .attr('x2', this.width)
      .attr('y1', this.y(0))
      .attr('y2', this.y(0))
      .style('stroke', '#000')
      .style('stroke-width', 1);
  }

  private drawWaterfall() {
    d3.selectAll(".bar").remove();
    d3.selectAll(".bartext").remove();

    let data = this.chartValues;

    this.g.selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', d => `bar ${d.class}`)
      .attr('x', d => this.x(d.xValue))
      .attr('y', d => d.yValue >= 0 ? this.y(d.yValue) : this.y(0))
      .attr('width', this.x.bandwidth())
      .attr('height', d => Math.abs(this.y(d.yValue) - this.y(0)))
      .attr('fill', d => d.yValue > this.positiveNegativeBreakpoint ? this.positiveBarColor : this.negativeBarColor);

    this.g.selectAll(".bartext")
      .data(this.chartValues)
      .enter()
      .append("text")
      .attr("class", "bartext")
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold")
      .attr('font-family', 'FontAwesome')
      .attr('font-size', function (d) { return d.size + 'em' })
      .attr('x', d => this.x(d.xValue) + this.x.bandwidth() / 2)
      .attr('y', d => d.yValue >= 0 ? this.y(d.yValue) - 10 : this.y(d.yValue) + 20)
      .attr("fill", d => d.yValue > this.positiveNegativeBreakpoint ? this.positiveBarColor : this.negativeBarColor)
      .text((d) => {
        if (!!this.positiveIcon) {
          return d.yValue > this.positiveNegativeBreakpoint ? d.yValue + this.positiveIcon : d.yValue + this.negativeIcon;
        } else {
          return d.yValue;
        }
      });
  }
}
