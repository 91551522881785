import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthState } from '@okta/okta-auth-js';
import { FormQuestions } from '../../models/form-questions';
import { FormUser } from '../../models/formUser';
import { HeaderService } from '../../services/header.service';
import { ClinicianDetails } from './shared/models/clinician-details';
import { EncounterType } from './shared/models/encounter-type';
import { Facility } from '../shared/models/facility';
import { Role } from './shared/models/role';
import { ServiceLine } from './shared/models/service-line';
import { AuthService } from './shared/services/auth.service';
import { ShadowRoundingService } from './shared/services/shadow-rounding.service';
import { NavigationEnd, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ClinicianFormControl } from './clinician-details/clinician-details.component';
import { switchMap, take, tap } from 'rxjs';

@Component({
  selector: 'shadow-rounding',
  templateUrl: './shadow-rounding.component.html',
  styleUrls: ['./shadow-rounding.component.scss']
})

export class ShadowRoundingComponent implements OnInit, OnDestroy {
  static userForms: FormUser[] = [];
  public title: string = "Shadow Rounding";
  public route: string = "/shadow-rounding"
  public userEmail: string = "";
  public username: string = "";
  public hideLanding: boolean = false;
  public hideClinicianDetails: boolean = true;
  public hideQuestionnaire: boolean = true;
  public hideResults: boolean = true;
  public hideConfirmation: boolean = true;
  public hideFinal: boolean = true;
  public landingPageForm: any;
  public clinicianDetailForm: any;
  public questionnaireForm: FormQuestions[];
  public emails: string[] = [];
  public clinicianDetails: ClinicianDetails[] = [];
  public clinician: ClinicianDetails = new ClinicianDetails();

  public facility: Facility = new Facility();
  public facilities: Facility[] = [];

  public serviceLine: ServiceLine = new ServiceLine();
  public serviceLines: ServiceLine[] = [];

  public role: Role = new Role();
  public encounterType: EncounterType = new EncounterType();
  public percentageScore: number = 0;
  public allAnswersNull = true;
  public isRefreshed = true;
  public encounterText: string = "";
  public serviceLineId: number = 0;
  public facilityId: number = 0;
  public roles: Role[] = [];
  public roleId: number = 0;
  public questionnaireSubmission: any;
  public userForms: FormUser[] = [];
  constructor(
    private authService: AuthService,
    private shadowRoundingService: ShadowRoundingService,
    private headerService: HeaderService,
    private router: Router) {
    this.shadowRoundingService.getAvailableRoles(0).pipe(take(1)).subscribe(roles => this.roles = roles);

    router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.refreshForm();
      }
    });
  }

  async ngOnInit() {
    this.headerService.displayHelp(false);
    this.authService.userInformation$
      .subscribe((authState: AuthState) => {
        let claims = authState?.idToken?.claims;
        this.username = claims?.name;
        this.userEmail = claims?.email;
      });

    this.headerService.setHeaderTitle(this.title, this.route);
    this.headerService.displayNavigation(false);
    this.headerService.navigate$.subscribe(shouldNavigate => {
      if (shouldNavigate) {
        this.navigateBack();
      }
    })
  }

  ngOnDestroy() {

  }

  updateLandingPageForm(form: any) {
    this.landingPageForm = form;
    if (this.landingPageForm.role.name != this.role) {
      this.clinicianDetails = [];
    }

    let roleName = this.landingPageForm.role;
    let role = this.roles.find(x => x.name == roleName);
    this.role = role;
    this.roleId = role.id;
    this.hideLanding = true;

    this.hideClinicianDetails = false;
    this.headerService.displayNavigation(true);
  }

  updateClinicianDetailForm(form: FormGroup<ClinicianFormControl>) {
    this.clinicianDetailForm = form;
    let clinician = form.get('clinician').value;
    let serviceLine = form.get('serviceLine').value
    this.serviceLine = this.serviceLines.find(x => x.name == serviceLine);
    this.serviceLineId = this.serviceLine.id;

    let facilityName = form.get('facility').value;
    this.facility = this.facilities.find(x => x.name == facilityName);
    this.facilityId = this.facility.facilityId;
    this.emails = [];
    this.emails.push(this.userEmail);
    this.emails.push(this.clinician.email)
    this.hideClinicianDetails = true;

    // Removing the "/" from the beginig of the route
    const urlRoute: string = this.router.url.slice(1);
    this.shadowRoundingService.getFormDetailsByUrlRoute(urlRoute).pipe(
      // Setting the object in the service will allow to be used in a more global way
      tap(form => this.shadowRoundingService.form = form),
      switchMap(form => this.shadowRoundingService.getPublishedQuestionnaireByUrlRoute(this.serviceLine.id))
   ).subscribe((questions: FormQuestions[]) => {
        if(questions !== null) {
          this.questionnaireForm = questions.sort(x => x.order);
        } else {
          this.questionnaireForm = [];
        }
      }, (error) => {
        //temp error return
        var errMsgDetails = "";
        if (error.error == null) { errMsgDetails = "Unknown error" } else { errMsgDetails = error.error };
        var errMsg = "Error: " + error.status + " - " + errMsgDetails;
        alert(errMsg);
      });
    this.hideQuestionnaire = false;
  }

  updateQuestionnaireForm(form: FormQuestions[]) {
    this.questionnaireForm = form;

    for (var i = 0; i < form.length; i++) {
      if (form[i].question == "Encounter Type") {
        this.encounterText = form[i].answer;
      }
    }

    this.calculateScore();
    this.hideQuestionnaire = true;
    this.hideResults = false;
  }

  updateResultsForm(form: any) {
    this.questionnaireSubmission = form;
    this.calculateScore();
    this.hideResults = true;
    this.hideConfirmation = false;
    this.headerService.displayNavigation(false);
  }

  updateConfirmation(emails: string[]) {
    this.questionnaireSubmission.ToEmails = emails.join(';');
    this.shadowRoundingService
      .sendAdditionalEmail(this.questionnaireSubmission)
      .subscribe((res) => {
        //alert(res[0]);
        this.hideFinal = false;
        this.headerService.displayNavigation(false);
      }, (error) => {
        var errMsgDetails = "";
        if (error.error == null) { errMsgDetails = "Unknown error" } else { errMsgDetails = error.error };
        var errMsg = "Error: " + error.status + " - " + errMsgDetails;
        alert(errMsg);
      }); this.hideConfirmation = true;
  }

  private calculateScore() {
    let currentScore = 0;
    let maxScore = 0;
    this.allAnswersNull = true;

    // loop through the form
    let form = this.questionnaireForm.filter(x => x.questionLookUpId != 2);
    form.forEach(x => {
      // get max value of the possible responses for each question
      if (x.responses && x.responses.length > 0) {

        var currentResponse = x.responses?.find(y => y.answer == x.answer);
        if (currentResponse?.answerValue != null && currentResponse?.answerValue != undefined) {
          this.allAnswersNull = false;
          currentScore += currentResponse.answerValue;
          var values = x.responses.map(x => {
            if (x && x.answerValue) {
              return x.answerValue
            } else {
              return 0;
            }
          });
          var max = Math.max(...values);
          maxScore += max;
        }
      }
    });

    if (!this.allAnswersNull) {
      // get the percentage (currentScore/maxScore)*100
      var percentage = (currentScore / maxScore) * 100;
      this.percentageScore = isNaN(percentage) ? 0 : percentage;
    } else {
      this.percentageScore = null;
    }
  }

  navigateBack() {
    if (!this.hideClinicianDetails) {
      this.hideClinicianDetails = true;
      this.hideLanding = false;
    }
    else if (!this.hideQuestionnaire) {
      this.hideClinicianDetails = false;
      this.hideQuestionnaire = true;
    }
    else if (!this.hideResults) {
      this.hideQuestionnaire = false;
      this.hideResults = true;
    }
  }


  refreshForm(): void {
    this.isRefreshed = false;
    this.hideLanding = false;
    this.hideClinicianDetails = true;
    this.hideQuestionnaire = true;
    this.hideResults = true;
    this.hideConfirmation = true;
    this.hideFinal = true;
    this.landingPageForm = null;
    this.clinicianDetailForm = null;
    this.questionnaireForm = [];
    this.emails = [];
    this.clinicianDetails = [];
    this.clinician = new ClinicianDetails();
    this.facility = new Facility();
    this.serviceLine = new ServiceLine();
    this.role = new Role();
    this.encounterType = new EncounterType();
    this.percentageScore = 0;
    this.allAnswersNull = true;
    this.encounterText = "";
    this.serviceLineId = 0;
    this.facilityId = 0;
    this.roleId = 0;
    setTimeout(() => {
      this.isRefreshed = true;
    }, 200)
  }

  handleGetFacilities(data) {
    this.shadowRoundingService.getAvailableFacilities(this.clinician.id, data.serviceLine)
      .pipe(take(1))
      .subscribe((facilities: Facility[]) => {
        this.facilities = facilities;

      }, (error) => {
        //temp error return
        var errMsgDetails = "";
        if (error.error == null) { errMsgDetails = "Unknown error" } else { errMsgDetails = error.error };
        var errMsg = "Error: " + error.status + " - " + errMsgDetails;
        alert(errMsg);
      });
  }

  handleGetServiceLines(id) {
    this.shadowRoundingService.getAvailableServiceLines(id)
      .pipe(take(1))
      .subscribe((serviceLines: ServiceLine[]) => {
        this.serviceLines = serviceLines;
      }, (error) => {
        //temp error return
        var errMsgDetails = "";
        if (error.error == null) { errMsgDetails = "Unknown error" } else { errMsgDetails = error.error };
        var errMsg = "Error: " + error.status + " - " + errMsgDetails;
        alert(errMsg);
      });
  }
  handleGetClinician(clinician) {
    this.clinician = clinician;
  }
}
