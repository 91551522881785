import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FormUser } from "../models/formUser";
import { BaseDataService } from "./base-data-service";
import { GenerateUrlService } from "./base-service";


@Injectable({
  providedIn: 'root'
})

export class UserFormDataService extends BaseDataService {
  constructor(
    private httpclient: HttpClient,
    private generateUrlService: GenerateUrlService) {
    super(httpclient);
  }

  getUserForm(userEmail: string): Observable<FormUser[]> {
    //var baseurl = "https://localhost:44439/api/GetUserForm/";
    this.generateUrlService.setApplicationUrl();
    console.log(this.generateUrlService.applicationBaseUrl)

    return this.httpclient.get<FormUser[]>(
      this.generateUrlService.applicationBaseUrl + 'GetUserForm/email/' + userEmail);

  }
}
