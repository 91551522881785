<div class="container">
  <div>
    <div class="welcome-message">Form Dashboard</div>
  </div>
  
  <div style="margin-top: 0em;">
    <!-- <span><a id="form" href="#">Shadow Rounding</a></span> -->
  </div>
  <li id="formlist" *ngFor="let form of userForms">
    <div class="row" id="formRow">
      <div id="form">
        <p>
          <span>
            {{form.name}}
          </span>
          <span style="float:right">
            <button (click)="openInfo(form)" style="background-color:white; border:none;"><img src="../../assets/Info.png" /></button>
          </span>
        </p>
        <p id="decsId_">{{form.formDescription}}</p>
      </div>
      <div class="formRow row">
        <p class="remove-padding">
          <button id="launchButton" (click)="navigate(form.urlRoute)">
            Launch
          </button>
        </p>
  
      </div>
  
    </div>
  </li>
  <mat-progress-spinner class="spinner" mode="indeterminate" *ngIf="loading"></mat-progress-spinner>  
</div>
