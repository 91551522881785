import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Facility } from "../../../shared/models/facility";
import { Role } from "../models/role";
import { ServiceLine } from "../models/service-line";
import { ShadowRoundingDataService } from "./shadow-rounding-data.service";
import { Form } from "../../../../models/form";


@Injectable({
  providedIn: 'root'
})
export class ShadowRoundingService {
  public form: Form = new Form();

  constructor(private shadowRoundingService: ShadowRoundingDataService) { }

  getAvailableServiceLines(physicianId: number): Observable<ServiceLine[]> {
    return this.shadowRoundingService.getServiceLines(physicianId);
  }

  getAvailableFacilities(physicianId: number, serviceLineId: number): Observable<Facility[]> {
    return this.shadowRoundingService.getFacilitiesForUser(physicianId, serviceLineId)
  }

  getAvailableRoles(serviceLineId: number): Observable<Role[]> {
    return this.shadowRoundingService.getRolesForUser(serviceLineId);
  }

  getClinicians(name:string) {
    return this.shadowRoundingService.getClinicians(name);
  }

  getQuestionnaire(serviceLineId: number) {
    return this.shadowRoundingService.getQuestionnaire(serviceLineId, this.form.id);
  }

  getPublishedQuestionnaireByUrlRoute(serviceLineId: number) {
    return this.shadowRoundingService.getPublishedQuestionnaireByUrlRoute(serviceLineId, this.form.urlRoute);
  }

  updateQuestionnaire(email: string, questionnaire: any) {
    this.shadowRoundingService.updateQuestionnaire(email, questionnaire);
  }

  submitQuestionnaire(questionResponse: any) {
    return this.shadowRoundingService.submitQuestionnaire(questionResponse)
  }

  sendAdditionalEmail(questionResponse: any) {
    return this.shadowRoundingService.sendAdditionalEmail(questionResponse)
  }

  getFormDetailsByUrlRoute(urlRoute: string): Observable<Form> {
    return this.shadowRoundingService.getFormDetailsByUrlRoute(urlRoute);
  }
}
