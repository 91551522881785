import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { filter, map, Observable, of, switchMap, first } from 'rxjs';
import { ConfirmStrings } from './models/confirm-strings';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { WindowEventService } from './window-event.service';
import { UnsavedChangesAction } from './unsaved-changes-dialog/unsaved-changes-action.enum';
import { UnsavedChangesDialogComponent, UnsavedChangesDialogData } from './unsaved-changes-dialog/unsaved-changes-dialog.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MonthEnum, MonthShortEnum } from './models/month.enum';
import { DayOfWeekEnum, DayOfWeekShortEnum } from './models/day-of-week.enum';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    public LOGIN_MODAL_ID: string = 'loginModal';
    public readonly loginDialogConfig: MatDialogConfig;
    public windowEventBeforeUnload$: Observable<BeforeUnloadEvent> = this.windowEventService.beforeUnload$.pipe(filter(e => !!e));

    constructor(private matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private windowEventService: WindowEventService) {
        this.loginDialogConfig = new MatDialogConfig();
        this.setLoginDialogConfigOptions();
    }

    private setLoginDialogConfigOptions() {
        this.loginDialogConfig.disableClose = true;
        this.loginDialogConfig.hasBackdrop = true;
        this.loginDialogConfig.minWidth = '300px';
        this.loginDialogConfig.minHeight = '250px';
        this.loginDialogConfig.id = this.LOGIN_MODAL_ID;
    }

    public popConfirmDialog(msg: string = "Are you sure?", confirmButtonText: string = "Discard", cancelButtonText: string = "Cancel"): Observable<boolean> {
        var dialogConf = new MatDialogConfig<ConfirmStrings>();
        dialogConf.data = {
            message: msg,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText
        };

      var dlg = this.openDialog(ConfirmModalComponent, dialogConf);
        return dlg.afterClosed();
    }

    public openDialog(componentType: ComponentType<any>, dialogConfig: MatDialogConfig = null): MatDialogRef<any> {
        if (!dialogConfig) {
            dialogConfig = new MatDialogConfig();
        }

        return this.matDialog.open(componentType, dialogConfig);
    }

    public closeAllDialogs(): void {
        this.matDialog.closeAll();
    }

    public closeDialog(id: string): void {
        const dialog = this.matDialog.getDialogById(id);
        if (dialog) {
            dialog.close();
        }
    }

    // will return true to continue navigating away (whether it save first or not),
    // and will return false to "Cancel all actions" and stay on page
    public handleUnsavedChangesCheckAndSave(
        hasChanges: () => boolean,
        saveFn: () => Observable<any>,
        hasValidationErrors: () => boolean
    ): Observable<boolean> {
        if (typeof hasChanges !== 'function') {
            return of(true);
        }

        if (hasChanges()) {
            let areValidationErrorsPresent = (typeof hasValidationErrors !== 'function')
                ? false
                : hasValidationErrors();

            return this.popUnsavedChangesDialog(areValidationErrorsPresent)
                .pipe(
                    switchMap((action: UnsavedChangesAction) => {
                        if (action == UnsavedChangesAction.Save) {
                            return saveFn().pipe(
                                first(),
                                map(_ => true)
                            );
                        } else if (action == UnsavedChangesAction.IgnoreSave) {
                            return of(true);
                        } else { // if Cancel, do nothing and stay on page
                            return of(false);
                        }
                    }));
        }
        else {
            return of(true);
        }
    }

    // will return user's decision from dialog (Save, Don't Save, or Cancel)
    private popUnsavedChangesDialog(hasValidationErrors: boolean): Observable<UnsavedChangesAction> {
        let dialogData: UnsavedChangesDialogData = {
            hasValidationErrors: hasValidationErrors
        };

        return this.openDialog(UnsavedChangesDialogComponent, {
            data: dialogData,
            disableClose: true
        })
            .afterClosed()
            .pipe(first());
    }

    public isDirty(initialViewModel: any, currentViewModel: any): boolean {
        const currentViewModelProperties = Object.getOwnPropertyNames(currentViewModel);

        for (let i = 0; i < currentViewModelProperties.length; i++) {
            const propertyName = currentViewModelProperties[i];

            // If values of same property are not equal,
            // currentViewModel is dirty

            if (currentViewModel[propertyName] !== initialViewModel[propertyName]) {
                // console.log('dirty field', propertyName, currentViewModel[propertyName], initialViewModel[propertyName]);
                return true;
            }
        }

        return false;
    }

    public handleErrorWithSnackBar(messageToDisplay: string, messageToLog: string, duration: number = null): void {
        console.log('error', messageToLog);
        this.snackBar.open(messageToDisplay, 'OK', {
            panelClass: ['error-snackbar'],
            duration: duration
        });
    }

    public handleSuccessWithSnackBar(messageToDisplay: string): void {
        this.snackBar.open(messageToDisplay, 'OK', {
            duration: 3000
        });
    }

    public getToday(): Date {
        return new Date();
    }

    public getDaysBetween(start: Date, end: Date): number {
        // Take the difference between the dates and divide by milliseconds per day.
        // Round to nearest whole number to deal with DST.
        return Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    }

    public getTodayPlusDays(days: number): Date {
        let today = this.getToday();
        today.setDate(today.getDate() + (days ?? 0));
        return today;
    }

    public getThirtyDaysBeforeToday(): Date {
        return this.getTodayPlusDays(-30);
    }

    public getStartOfCurrentYear(): Date {
        return new Date(this.getToday().getFullYear(), 0);
    }

    public stringifyDate(date: Date): string {
        return date && (new Date(date.toString())).toDateString().substr(4);
    }

  public getWorkdaysfromDate(date: Date, dayCount: number): Date {
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    var result = 0;

    var currentDay = date.getDay();

    if (currentDay == 3 || currentDay == 4 || currentDay == 5) {
      dayCount += 2;
    }
    var newDate = date.toDateString();
    var updateDate = new Date(newDate);
    updateDate.setDate(updateDate.getDate() + dayCount);
    return updateDate;
  }

  getMonthNameFromNumber(month: number): string {
    return MonthEnum[month];
  }

  getShortMonthNameFromNumber(month: number): string {
    return MonthShortEnum[month];
  }

  getMonthNumberFromName(month: string): number {
    return MonthEnum[month];
  }

  getShortMonthNumberFromNumber(month: string): number {
    return MonthShortEnum[month];
  }

  getDayofWeekFromDate(date: Date): string {
    let dayofWeek = date.getDay()
    return DayOfWeekEnum[dayofWeek];
  }

  getShortDayofWeekFromDate(date: Date): string {
    let dayofWeek = date.getDay()
    return DayOfWeekShortEnum[dayofWeek];
  }

  getDateFromDateString(date: string, daysFromDate: number): Date {
    var currentDate = new Date(date);
    var dateFrom = new Date(currentDate.setDate(currentDate.getDate() + daysFromDate)).toDateString();
    return new Date(dateFrom);
  }

  getDateFromDate(date: Date, daysFromDate: number): Date {
    var currentDate = new Date(date);
    var dateFrom = new Date(currentDate.setDate(currentDate.getDate() + daysFromDate)).toDateString();
    return new Date(dateFrom);
  }
}
