import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmStrings } from '../models/confirm-strings';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})

export class ConfirmModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmStrings) { }
}
