import { Component, OnInit } from '@angular/core';
import { AdministrationDetails } from '../../models/administration-details';
import { AdministrationItem } from '../../models/administration-item';
import { FeatureFlag } from '../../models/feature-flag';
import { AuthService } from '../../modules/shadow-rounding/shared/services/auth.service';
import { AdminService } from '../../services/admin.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  username = '';
  hasChanges = false;
  featureFlags: FeatureFlag[];
  adminData: AdministrationItem[];
  isViewable = false;
  currentEnvironment: string = '';
  public environments = ['Test', 'local', 'Development'];

  constructor(private adminService: AdminService, private authService: AuthService, private router: Router) {
    this.currentEnvironment = environment.hostingEnvironment;
    this.isViewable = this.environments.indexOf(this.currentEnvironment) > -1;
    if (!this.isViewable) {
      router.navigate(['/']);
    }
    authService.authService.authState$.subscribe(x => {
      this.username = x?.idToken?.claims?.name;
    });

    adminService.adminData.subscribe(x => {
      if (!!x) {
        this.adminData = x.administrationItems;
        this.featureFlags = x.featureFlags;
      }
    });
  }

  ngOnInit() {
    this.adminService.adminData.subscribe();
    this.adminService.featureFlags.subscribe();
  }

  updateFeatureFlags(e, flag) {
    let featureFlag = this.featureFlags.find(x => x.id == flag.id);
    featureFlag.value = e.checked;
    this.adminService.updateFeatureFlag(featureFlag).subscribe();
  }

  save() {
    this.adminService.updateAdminItems(this.adminData).subscribe();
  }
}
