import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';

@Component({
  selector: 'uf-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})


export class BarChartComponent implements OnInit {

  STATISTICS = [
    { xValue: 'A', yValue: 8167 },
    { xValue: 'B', yValue: -1492 },
    { xValue: 'C', yValue: 2782 },
    { xValue: 'J', yValue: -153 },
    { xValue: 'K', yValue: 772 },
    { xValue: 'L', yValue: 4025 },
    { xValue: 'M', yValue: 2406 },
    { xValue: 'N', yValue: 6749 },
  ];
  title = 'Bar Chart';

  private width: number;
  private height: number;
  private margin = { top: 20, right: 20, bottom: 30, left: 40 };

  private x: any;
  private y: any;
  private svg: any;
  private g: any;

  constructor() { }

  ngOnInit() {
    this.initSvg();
    this.initAxis();
    this.drawAxis();
    this.drawBars();
  }

  private initSvg() {
    this.svg = d3.select('svg');
    this.width = +this.svg.attr('width') - this.margin.left - this.margin.right;
    this.height = +this.svg.attr('height') - this.margin.top - this.margin.bottom;
    this.g = this.svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  private initAxis() {
    this.x = d3Scale
      .scaleBand()
      .rangeRound([0, this.width])
      .padding(0.1);

    this.y = d3Scale
      .scaleLinear()
      .rangeRound([this.height, 0]);

    this.x.domain(this.STATISTICS.map((d) => d.xValue));
    this.y.domain([d3Array.min(this.STATISTICS, (d) => d.yValue), d3Array.max(this.STATISTICS, (d) => d.yValue)]);
  }

  private drawAxis() {
    this.g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3Axis.axisBottom(this.x));
    this.g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3Axis.axisLeft(this.y).ticks(10))
      .append('text')
      .attr('class', 'axis-title')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .text('yValue');
  }

  private drawBars() {
    this.g.selectAll('.bar')
      .data(this.STATISTICS)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => this.x(d.xValue))
      .attr('y', (d) => this.y(d.yValue))
      .attr('width', this.x.bandwidth())
      .attr('height', (d) => this.height - this.y(d.yValue))
      .attr("fill", (d) => {
        console.log(d);
        return d.yValue > .05 ? "steelblue" : "red"
      });

    this.g.selectAll(".bartext")
      .data(this.STATISTICS)
      .enter()
      .append("text")
      .attr("class", "bartext")
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold")
      .attr('font-family', 'FontAwesome')
      .attr('font-size', function (d) { return d.size + 'em' })
      .attr('x', (d) => this.x(d.xValue) + this.x.bandwidth() / 2)
      .attr('y', (d) => this.y(d.yValue) - 10)
      .attr("fill", (d) => {
        console.log(d);
        return d.yValue > .05 ? "steelblue" : "red"
      })
      .text(function (d) {

        return d.yValue > 0 ? d.yValue + '\uf062' : d.yValue + '\uf063';
      });
  }

}
