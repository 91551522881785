import { Injectable, RendererFactory2, Renderer2, OnDestroy } from '@angular/core';
import { Observable, Subject, fromEventPattern } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class WindowEventService implements OnDestroy {
  private _destroy$ = new Subject();

    public beforeUnload$!: Observable<BeforeUnloadEvent>;

    constructor(private rendererFactory2: RendererFactory2) {
        const renderer = this.rendererFactory2.createRenderer(null, null);

        this.createEventObservable(renderer, "window", "beforeunload");
    }

    ngOnDestroy() {
        this._destroy$.complete();
    }

  private createEventObservable(renderer: Renderer2, target: string, event: string) {
        let removeEventListener: () => void;

        const createEventListener = (handler: (e: BeforeUnloadEvent) => boolean | void) => {
                removeEventListener = renderer.listen(target, event, handler);
        };

      this.beforeUnload$ = fromEventPattern<BeforeUnloadEvent>(createEventListener, () =>
          removeEventListener())
          .pipe(takeUntil(this._destroy$));
    }
}
