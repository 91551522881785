<div class="ui text container" *ngIf="isRefreshed">
  <landing-page [hidden]="hideLanding"
                [userEmail]="userEmail"
                [username]="username"
                [currentRoles]="roles"
                (formEmitter)=updateLandingPageForm($event)>
  </landing-page>
  <clinician-details [hidden]="hideClinicianDetails"
                     [currentClinicianDetails]="clinicianDetails"
                     [currentFacilities]="facilities"
                     [currentServiceLines]="serviceLines"
                     (formEmitter)=updateClinicianDetailForm($event)
                     (clinicianEmitter)="handleGetClinician($event)"
                     (facilityEmitter)="handleGetFacilities($event)"
                     (serviceLineEmitter)="handleGetServiceLines($event)">

  </clinician-details>
  <questionnaire-page [hidden]="hideQuestionnaire"
                      [questionnaireForm]="questionnaireForm"
                      (formEmitter)=updateQuestionnaireForm($event)>

  </questionnaire-page>
  <results-page *ngIf="!hideResults"
                [facility]="facility.name"
                [serviceLine]="serviceLine.name"
                [clinicianRole]="clinicianRole"
                [clinician]="clinician"
                [questionnaireForm]="questionnaireForm"
                [percentageScore]="percentageScore"
                [allAnswersNull]="allAnswersNull"
                [serviceLineId]="serviceLineId"
                [facilityId]="facilityId"
                [roleId]="roleId"
                [role]="role"
                [encounterText]="encounterText"
                [clinicianRoleId]="clinicianRoleId"
                (formEmitter)=updateResultsForm($event)>

  </results-page>
  <confirmation-page [hidden]="hideConfirmation"
                     [emails]="emails"
                     [facility]="facility"
                     [serviceLine]="serviceLine"
                     [clinicianRole]="clinicianRole"
                     [clinician]="clinician"
                     [questionnaireForm]="questionnaireForm"
                     [percentageScore]="percentageScore"
                     [allAnswersNull]="allAnswersNull"
                     [encounterText]="encounterText"
                     (resetFormEmitter)=refreshForm()
                     (formEmitter)=updateConfirmation($event)>

  </confirmation-page>
  <success-page [hidden]="hideFinal" (formEmitter)=refreshForm()></success-page>
</div>
