import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ICanDeactivate } from './icandeactivate';
import { take, map, first, filter } from 'rxjs/operators';
import { ClaimTypes, ModuleAccessValues } from '../models/claim-types.enum';
import { JwtModel } from '../models/jwt-model';
import { AuthService } from '../modules/shadow-rounding/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ShadowRoundingGuard implements CanActivate, CanDeactivate<ICanDeactivate> {

  constructor(private authService: AuthService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    return this.authService.jwt$.pipe(
      filter(x => x !== null && x.isAuthenticated()),
      take(1),
      map((jwt: JwtModel) => {
        return jwt.canAccessModule(ModuleAccessValues.ShadowRounding);
      })
    );
  }

  canDeactivate(component: ICanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean> {

    return component.canDeactivate()
      // below added mostly for ease of debugging
      .pipe(
        first(),
        map(x => {
          return x;
        })
      );
  }

}
