import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdministrationDetails } from '../models/administration-details';
import { AdministrationItem } from '../models/administration-item';
import { FeatureFlag } from '../models/feature-flag';
import { BaseDataService } from './base-data-service';
import { GenerateUrlService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class AdminDataService extends BaseDataService {
  private endpoint: string = '/api'

  constructor(
    private httpclient: HttpClient,
    private generateUrlService: GenerateUrlService) {
    super(httpclient);
    this.generateUrlService.setApplicationUrl();
    this.endpoint = this.generateUrlService.getHostUrl();
  }

  public getAdminData(email: string): Observable<AdministrationDetails> {
    return this.Get(`${this.endpoint}/administration/email/${email}`)
  }

  public getFeatureFlags(email: string): Observable<FeatureFlag[]> {
    return this.Get(`${this.endpoint}/administration/email/${email}/featureFlags`)

  }

  public setFeatureFlag(flag: FeatureFlag, email: string): Observable<any> {
    return this.Put(`${this.endpoint}/administration/email/${email}/featureFlags`, flag)
  }
  public updateAdminItems(items: AdministrationItem[], email: string): Observable<any> {
    return this.Put(`${this.endpoint}/administration/email/${email}/adminItems`, items)
  }
}
